import { RectSkeleton } from '@invisible/ui/skeleton'

type HelpCardImage = React.ReactElement | string
export interface HelpCardProps {
  title: string
  subTitle: string
  img?: HelpCardImage
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void
}

const HelpCardSkeleton = () => <RectSkeleton width='100%' height={112} />

const HelpCard = ({ title, subTitle, img, onClick, onMouseOver }: HelpCardProps) => (
  <div
    className='hover:shadow-surround box-border flex h-28 w-full cursor-pointer items-center space-x-4 rounded-md border border-solid border-gray-200 bg-white p-3'
    onClick={onClick}
    onMouseOver={onMouseOver}>
    {typeof img === 'string' ? (
      <img src={img} alt='img' className='h-20 w-20 shrink-0' />
    ) : img ? (
      <div className='h-20 w-20 shrink-0'>{img}</div>
    ) : null}
    <div className='flex flex-col justify-center gap-0.5'>
      <div className='text-header text-base font-bold'>{title}</div>
      <div className='text-paragraphs'>{subTitle}</div>
    </div>
  </div>
)

export { HelpCard, HelpCardSkeleton }
