import { logger } from '@invisible/logger/client'
import { trackClickEvent, trackHoverEvent } from '@invisible/segment'
import { useQuery } from '@invisible/trpc/client'
import { HelpCard, HelpCardSkeleton } from '@invisible/ui/help-card'
import { Astronaut, Collaborating, LadyWorking } from '@invisible/ui/images'
import { Modal } from '@invisible/ui/modal'
import { isEmpty } from 'lodash/fp'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

interface PortalHomeVideoObj {
  title1: string
  subTitle1: string
  videoUrl1: string
  thumbnailUrl1: React.ReactElement
  title2: string
  subTitle2: string
  videoUrl2: string
  thumbnailUrl2: React.ReactElement
  title3: string
  subTitle3: string
  videoUrl3: string
  thumbnailUrl3: React.ReactElement
}
interface PortalHomeVideo {
  title: string
  subTitle: string
  videoUrl: string
  img: React.ReactElement
}

// Hard coded videos that can be used in case query to back-end fails or there are no videos in the database
const PortalHomeVideos: PortalHomeVideo[] = [
  {
    title: 'How do I launch a process?',
    subTitle: 'Create or map your own process.',
    videoUrl:
      'https://www.loom.com/embed/f5741476f64548178f8e44583320acc0?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
    img: <Astronaut />,
  },
  {
    title: 'How do I access reports?',
    subTitle: 'Review and share your process details.',
    videoUrl:
      'https://www.loom.com/embed/ffacc87612c34e16b70b78d92af36b33?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
    img: <LadyWorking />,
  },
  {
    title: 'How do I invite a colleague?',
    subTitle: 'Help colleagues start Worksharing.',
    videoUrl:
      'https://www.loom.com/embed/ededfc7277994791b18cc4bd00217d4b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
    img: <Collaborating />,
  },
]

export interface VideoModalData {
  title: string
  videoUrl: string
}

export const VideoModalContext = createContext<{
  videoModalData: VideoModalData | null
  setVideoModalData: Dispatch<SetStateAction<VideoModalData | null>>
}>({
  videoModalData: null,
  setVideoModalData: () => null,
})

export const VideoModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [videoModalData, setVideoModalData] = useState<VideoModalData | null>(null)

  return (
    <VideoModalContext.Provider value={{ videoModalData, setVideoModalData }}>
      {children}
    </VideoModalContext.Provider>
  )
}

export const useVideoModalContext = () => {
  const { videoModalData, setVideoModalData } = useContext(VideoModalContext)

  return { videoModalData, setVideoModalData }
}

export const usePortalHomeVideos = () => {
  const {
    data: portalHomeVideo,
    isLoading: portalVideoIsLoading,
    isError,
  } = useQuery([
    'appContent.findByName',
    {
      name: 'PortalHomeVideo',
    },
  ])
  const { videoModalData, setVideoModalData } = useVideoModalContext()

  const handleOnClick = (modalProps: VideoModalData) => {
    setVideoModalData(modalProps)
  }

  if (portalVideoIsLoading) {
    return (
      <>
        {PortalHomeVideos.map((_, i) => (
          <HelpCardSkeleton key={i} />
        ))}
      </>
    )
  }
  if (isError) {
    logger.error('Error querying Portal Home videos')
    return null
  }
  const portalStartGuideData = portalHomeVideo?.data as unknown as
    | PortalHomeVideoObj
    | null
    | undefined

  return (
    <>
      {portalStartGuideData && !isEmpty(portalStartGuideData) ? (
        <>
          <HelpCard
            title={portalStartGuideData.title1}
            subTitle={portalStartGuideData.subTitle1}
            img={portalStartGuideData?.thumbnailUrl1 ?? <Astronaut />}
            onClick={() => {
              trackClickEvent(`home_video_${portalStartGuideData.title1}`)
              handleOnClick({
                title: portalStartGuideData.title1,
                videoUrl: portalStartGuideData.videoUrl1,
              })
            }}
            onMouseOver={() => {
              trackHoverEvent(`home_video_${portalStartGuideData.title1}`)
            }}
          />
          <HelpCard
            title={portalStartGuideData.title2}
            subTitle={portalStartGuideData.subTitle2}
            img={portalStartGuideData?.thumbnailUrl2 ?? <LadyWorking />}
            onClick={() => {
              trackClickEvent(`home_video_${portalStartGuideData.title2}`)
              handleOnClick({
                title: portalStartGuideData.title2,
                videoUrl: portalStartGuideData.videoUrl2,
              })
            }}
            onMouseOver={() => {
              trackHoverEvent(`home_video_${portalStartGuideData.title2}`)
            }}
          />
          <HelpCard
            title={portalStartGuideData.title3}
            subTitle={portalStartGuideData.subTitle3}
            img={portalStartGuideData?.thumbnailUrl3 ?? <Collaborating />}
            onClick={() => {
              trackClickEvent(`home_video_${portalStartGuideData.title3}`)
              handleOnClick({
                title: portalStartGuideData.title3,
                videoUrl: portalStartGuideData.videoUrl3,
              })
            }}
            onMouseOver={() => {
              trackHoverEvent(`home_video_${portalStartGuideData.title3}`)
            }}
          />
        </>
      ) : (
        PortalHomeVideos.map(({ title, subTitle, img, videoUrl }, i) => (
          <HelpCard
            key={i}
            title={title}
            subTitle={subTitle}
            img={img}
            onClick={() => {
              trackClickEvent(`home_video_${title}`)
              handleOnClick({
                title,
                videoUrl,
              })
            }}
            onMouseOver={() => {
              trackHoverEvent(`home_video_${title}`)
            }}
          />
        ))
      )}
      <Modal
        visible={!!videoModalData}
        blurred={true}
        title={videoModalData?.title}
        onClose={() => setVideoModalData(null)}>
        <iframe
          src={videoModalData?.videoUrl}
          frameBorder='0'
          allowFullScreen={true}
          className='h-[550px] w-[800px] '
        />
      </Modal>
    </>
  )
}
