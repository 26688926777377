import { useQuery } from '@invisible/trpc/client'
import { Banner, BannerSkeleton } from '@invisible/ui/banner'
import { Astronaut } from '@invisible/ui/images'

import { useVideoModalContext, VideoModalData } from './use-portal-home-videos'

const BANNER_VIDEO_DATA = {
  title: 'How do I launch a process?',
  subTitle: 'Create or map your own process.',
  videoUrl:
    'https://www.loom.com/embed/f5741476f64548178f8e44583320acc0?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
  img: <Astronaut />,
}

const HOME_BANNER_FIELDS: BannerFields = {
  style: 'PRIMARY',
  title: 'Learn How to Use the New Invisible App',
  subtitle: `Here's a picture of a cute puppy!`,
  bannerImageUrl:
    'https://storage.googleapis.com/inv-lambdas/40c8355fdced25222aa88b8cb4bcd8ea28a03135.jpeg',
}

interface BannerFields {
  style: 'PRIMARY' | 'SECONDARY'
  title: string
  subtitle: string
  bannerImageUrl: string
}

export const usePortalBanner = () => {
  const { data: bannerData, isLoading: bannerDataIsLoading } = useQuery([
    'appContent.findByName',
    {
      name: 'PortalBanner',
    },
  ])

  const { setVideoModalData } = useVideoModalContext()

  const handleOnClick = (modalProps: VideoModalData) => {
    setVideoModalData(modalProps)
  }

  const portalBannerData = bannerData?.data as unknown as BannerFields | null | undefined

  if (!portalBannerData && bannerDataIsLoading) {
    return <BannerSkeleton />
  }

  if (!portalBannerData && !bannerDataIsLoading) {
    return (
      <Banner
        variant='primary'
        title={HOME_BANNER_FIELDS.title}
        subtitle={HOME_BANNER_FIELDS.subtitle}
        bannerImageUrl={HOME_BANNER_FIELDS.bannerImageUrl}
        ctaLabel='Learn More'
        ctaOnClick={() =>
          handleOnClick({
            title: BANNER_VIDEO_DATA.title,
            videoUrl: BANNER_VIDEO_DATA.videoUrl,
          })
        }
      />
    )
  }

  return (
    <Banner
      variant={portalBannerData?.style ?? 'primary'}
      title={portalBannerData?.title ?? HOME_BANNER_FIELDS.title}
      subtitle={portalBannerData?.subtitle ?? HOME_BANNER_FIELDS.subtitle}
      bannerImageUrl={portalBannerData?.bannerImageUrl ?? HOME_BANNER_FIELDS.bannerImageUrl}
    />
  )
}
