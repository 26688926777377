import { useSession } from '@invisible/authentication/client'
import { Header, PageHeader, useBreadcrumbsContext } from '@invisible/common/layouts'
import { SectionHeader } from '@invisible/ui/section-header'

import ComingSoon from './new-coming-soon'
import { usePortalBanner } from './use-portal-banner'
import { usePortalHomeVideos, VideoModalContextProvider } from './use-portal-home-videos'
import { usePortalTestimonials } from './use-portal-testimonials'

export const HomeContents = () => {
  const { data: session } = useSession()

  useBreadcrumbsContext(['Home'])

  const banner = usePortalBanner()
  const videos = usePortalHomeVideos()
  const testimonials = usePortalTestimonials()

  return (
    <>
      <ComingSoon />
      <Header>
        <PageHeader
          title={`Welcome, ${session?.user?.name?.split(' ')?.[0]}`}
          subtitle={'Here are quick links to get started with Invisible'}
        />
      </Header>

      <div className='max-w-screen-2xl p-4'>
        <div className='box-border flex w-full flex-col items-center space-y-4'>
          {banner}
          <div className='flex w-full flex-col items-start space-y-4'>
            <SectionHeader
              title='Quick Start Guides'
              subtitle='Do you love Invisible? Invites made easy. Sharing is caring.'
            />
            <div className='grid w-full grid-cols-2 gap-3 xl:grid-cols-3'>{videos}</div>
          </div>
          <div className='flex flex-col items-start space-y-4'>
            <SectionHeader
              title='What do others say about Invisible?'
              subtitle='The not-so Invisible reason why we exist - our clients.'
            />
            <div className='grid w-full grid-cols-2 gap-3 xl:grid-cols-3'>{testimonials}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export const Home = () => (
  <VideoModalContextProvider>
    <HomeContents />
  </VideoModalContextProvider>
)
