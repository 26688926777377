import { DoubleQuote } from '@invisible/ui/images'

/* eslint-disable-next-line */
export interface TestimonialProps {
  quote: string
  name: string
  occupation: string
}

export function Testimonial({ quote, name, occupation }: TestimonialProps) {
  return (
    <div className='box-border flex min-h-[180px] flex-col items-start space-y-2 rounded-md border border-solid border-gray-200 bg-white p-3'>
      <DoubleQuote />
      <div className='flex-grow text-gray-700'>{quote}</div>
      <div className='font-bold text-purple-600'>{name}</div>
      <div className='text-gray-700'>{occupation}</div>
    </div>
  )
}

export default Testimonial
