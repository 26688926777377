import { styled } from '@invisible/ui/themes'
import Image from 'next/image'
import React from 'react'

const Container = styled.div`
  display: flex;
  background-color: #ffffff;
  align-items:flex-start;
  padding-left:2rem;
  padding-right:2rem;
  padding-top:1rem;
  padding-bottom:1rem;
`

const BottomLeftTextContainer = styled.div`
  margin-left: 10px;
`

const BoldTitleText = styled.h5`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.30rem;
  color: black;
  margin:0
`

const BottomSubTitle = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: black;
  opacity: 0.5;
  margin:0
`

const SignIn = () => (
  <Container>
    <Image
      src='https://static.inv.tech/images/favicon/favicon.png'
      alt='invisible'
      width='50'
      height='50'
    />

    <BottomLeftTextContainer>
      <BoldTitleText>A new look is coming soon</BoldTitleText>
      <BottomSubTitle>
        Invisible is upgrading the Client Portal to the Invisible Workplace, with a modern look and
        feel and process details at your fingertips.
      </BottomSubTitle>
      <BottomSubTitle>
        Billing will be unavailable on Portal beginning March 31, 2024 and your Operations Manager
        can provide you with billing details until your access to Invisible Workplace is granted.
      </BottomSubTitle>
    </BottomLeftTextContainer>
  </Container>
)

export default SignIn
