import { useQuery } from '@invisible/trpc/client'
import { Testimonial } from '@invisible/ui/testimonial'
import { isEmpty } from 'lodash/fp'

interface ITestimonialObject {
  quote1: string
  name1: string
  occupation1: string
  quote2: string
  name2: string
  occupation2: string
  quote3: string
  name3: string
  occupation3: string
}

const PortalHomeTestimonials: ITestimonialObject = {
  quote1: `Invisible has done outstanding work that has materially increased the team's productivity...we plan to expand our services with Invisible.`,
  name1: 'Paul Wilson',
  occupation1: 'CFO at Ark Invest',
  quote2:
    'Invisible is our strategic growth partner providing us with business intelligence to expand into new markets. They exceeded our expectations in both cost and quality while improving our outcomes.',
  name2: 'Morgan Weber',
  occupation2: 'Commercial Commercial Manager at Klarna',
  quote3:
    'I feel like someone had broken into my home and cleaned the bathroom floors while I slept.    ',
  name3: 'Nathan Heller',
  occupation3: 'Journalist at The New Yorker',
}

export const usePortalTestimonials = () => {
  const { data: portalTestimonials } = useQuery([
    'appContent.findByName',
    {
      name: 'PortalTestimonials',
    },
  ])

  const data = !isEmpty(portalTestimonials?.data)
    ? (portalTestimonials?.data as unknown as ITestimonialObject | null | undefined)
    : PortalHomeTestimonials

  if (data)
    return (
      <>
        <Testimonial name={data.name1} quote={data.quote1} occupation={data.occupation1} />
        <Testimonial name={data.name2} quote={data.quote2} occupation={data.occupation2} />
        <Testimonial name={data.name3} quote={data.quote3} occupation={data.occupation3} />
      </>
    )
}
