interface SectionHeaderProps {
  title: string
  subtitle: string
}

const SectionHeader = ({ title, subtitle }: SectionHeaderProps) => (
  <div className='flex w-full flex-col items-start gap-0.5'>
    <div className='text-xl font-bold'>{title}</div>
    <div className='text-paragraphs text-sm'>{subtitle}</div>
  </div>
)

export { SectionHeader }
